import login from "./api/login";
import usercategories from "./api/usercategories";
import categories from "./api/categories";
import fields from "./api/fields";
import answers from "./api/answers";
import status from "./api/status";
import companies from "./api/companies";
import categorypilotes from "./api/categorypilotes";
import bugs from "./api/bugs";

import Toastify from "toastify-js";

export const url_core: string = process.env.REACT_APP_URL_CORE || "https://qualif.winylo.fr";
export const host: string = process.env.REACT_APP_HOST_API || "https://api.kaizen.qualif.c-koya.tech";
export const host_core: string = process.env.REACT_APP_HOST_CORE || "https://api.qualif.winylo.fr";

export interface ErrorMessage {
  error: boolean;
  message: string;
}

function ignore403Redirect(): boolean {
  return window.location.pathname.startsWith("/c/");
}

export const checkStatus = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      window.location.href = "/login#redirect=" + window.location.pathname;
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    } else if (res.status === 403) {
      if (ignore403Redirect()) return res;
      return res.json().then((error: any) => {
        window.location.href = `/error#code=${error.code}&message=${error.message}`;
        throw new Error(error);
      });
    } else {
      return res.json().then((data) => {
        Toastify({
          text: data.message,
          duration: 3000,
          close: true,
          gravity: "bottom", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
        }).showToast();

        throw new Error(JSON.stringify(data));
      });
    }
  }
};

export const checkStatusQuiet = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    return res.text().then((msg: string) => {
      throw new Error(msg);
    });
  }
};

export const checkStatusWithoutError = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      window.location.href = "/login#redirect=" + window.location.pathname;
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    } else if (res.status === 403) {
      if (ignore403Redirect()) return res;
      return res.json().then((error: any) => {
        window.location.href = `/error#code=${error.code}&message=${error.message}`;
        throw new Error(error);
      });
    } else {
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    }
  }
};

export const parseFormData = (obj: { [key: string]: any }) => {
  let keys = Object.keys(obj);
  let bodyFormData = new FormData();

  keys.forEach((k) => {
    if (obj[k] === null) return;
    bodyFormData.set(k, obj[k]);
  });

  return bodyFormData;
};

const api = {
  loginApi: login,
  usercategories,
  categories,
  fields,
  answers,
  status,
  companies,
  categorypilotes,
  bugs,
};

export default api;
