import style from "./Home.module.css";
import Utils from "../../components/Utils";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { patchNotes } from "../../utils/patchNotes";
import { ShepherdTourContext } from "react-shepherd";
import { createCategorySteps } from "../../utils/onboardingtours/create-category.steps"; // étapes pour l'onboarding tour "création d'une rubrique"
import { useHistory, useLocation } from "react-router-dom";
import { shareQRCodeSteps } from "../../utils/onboardingtours/share-qrcode.steps";
import { virtualBoardSteps } from "../../utils/onboardingtours/virtual-board.steps";
import { detailledCategoriesSteps } from "../../utils/onboardingtours/detailled-categories.steps";
import { detailledProposalsSteps } from "../../utils/onboardingtours/detailled-proposals.steps";
import { detailledProposalsErrorSteps } from "../../utils/onboardingtours/detailled-proposals-error.steps";
import { useMutation } from "react-query";
import api from "../../utils/api";
import { isAdmin, MY_DOMAIN, startOnBoardingTour } from "../../utils/utils";

import qs from "qs";
import { userInfo } from "os";
import { UserContextType, useUser } from "../../utils/context/User";

interface Props {
  title: string;
  children?: ReactElement;
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function ContainerVideo() {
  return (
    <>
      <span className={style.textContent}>Découvrez les avantages d'Intélligence Collective en vidéo !</span>
      <div className={style.itemContainer}>
        <embed className={style.video} src="https://www.youtube.com/embed/RMpDfCDAY78" title="YouTube video player"></embed>
      </div>
    </>
  );
}

function HomeContainer(props: Props): ReactElement {
  return (
    <>
      <div className={classNames(style.homeContainer, props.handleClick && style.clickable)} onClick={props.handleClick}>
        <div className={style.homeContainerTitle}>{props.title}</div>
        <div className={style.homeContainerChildren}>{props.children}</div>
      </div>
    </>
  );
}

export default function Home(): ReactElement {
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<boolean>(false);
  const [isModalNeedToCreateCategoryOpen, setIsModalNeedToCreateCategoryOpen] = useState<boolean>(false);

  const tour = useContext(ShepherdTourContext);
  const history = useHistory();
  const [user] = useUser() as UserContextType;

  const [onBoardingStart, setOnBoardingStart] = useState<number>(-1);

  let queryParams = useQueryParams();

  const ContainerSponsorship = () => {
    return (
      <>
        <span className={style.textContent}>Bénéficiez de Boite à idées digitale gratuitement en parrainant d'autres entreprises</span>
        <div className={style.itemContainer}>
          <div className={style.itemImage} style={{ backgroundImage: `url(${MY_DOMAIN}/sponsorship.png)` }} />
        </div>
      </>
    );
  };

  const ContainerPatchNotes = () => {
    let nbPreviews = 0;
    const maxPreviews = 6;

    function getPreviews() {
      return patchNotes.map((pn) =>
        pn.previews.map((p) => {
          if (nbPreviews < maxPreviews) {
            nbPreviews++;
          } else {
            return;
          }
          return p;
        })
      );
    }

    return (
      <>
        <span className={style.textContent}>Découvrez les dernières nouveautés</span>
        <div className={style.itemList}>
          {patchNotes ? <>{getPreviews()}</> : <div>‎ </div>}
          <span className={style.clickableItem} style={{ display: "flex", justifyContent: "center" }}>
            Voir plus
          </span>
        </div>
      </>
    );
  };

  const ContainerMobileApps = () => {
    return (
      <>
        <span className={style.textContent}>Notre application web est couplée avec une application mobile</span>
        <div className={style.itemContainer}>
          <div className={classNames(style.itemImage, style.mobileImage)} style={{ backgroundImage: `url(${MY_DOMAIN}/mobile-apps.png)` }} />
        </div>
      </>
    );
  };

  const ContainerLinkableApps = () => {
    return (
      <>
        <span className={style.textContent}>Nos applications sont connectables entre elles</span>
        <div className={style.itemContainer}>
          <div className={style.itemImage} style={{ backgroundImage: `url(${MY_DOMAIN}/linkable-apps.svg)` }} />
        </div>
      </>
    );
  };

  const { mutate: getStats } = useMutation(api.companies.getStats, {
    onSuccess: (stats) => {
      stats.answerEnAttente > 0 || stats.answerEnCours > 0
        ? startOnBoardingTour(tour, detailledProposalsSteps(history))
        : startOnBoardingTour(tour, detailledProposalsErrorSteps(history, setOnBoardingStart));
    },
  });

  function ContainerTutorial() {
    return (
      <>
        <span className={style.textContent}>Découvrez les tutoriels participatifs</span>
        <div className={style.itemList}>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(0)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Créer une rubrique
          </div>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(1)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Options d'une rubrique
          </div>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(2)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Partager une rubrique
          </div>
          {/* <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(3)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Remonter une idée
          </div> */}
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(4)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Traitement des propositions
          </div>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(5)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Tableau étiquette virtuelle
          </div>
        </div>
      </>
    );
  }

  function useQueryParams() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (onBoardingStart !== -1) {
      switch (onBoardingStart) {
        case 0:
          startOnBoardingTour(tour, createCategorySteps(history));
          break;
        case 1:
          startOnBoardingTour(tour, detailledCategoriesSteps(history));
          break;
        case 2:
          startOnBoardingTour(tour, shareQRCodeSteps(history));
          break;
        // case 3:
        //   startOnBoardingTour(tour, pushIdeaSteps(history));
        //   break;
        case 4:
          getStats();
          break;
        case 5:
          startOnBoardingTour(tour, virtualBoardSteps(history));
          break;
        default:
          console.log(`%conBoardinStart : bad index (${onBoardingStart})`, "color: red");
          break;
      }
      setOnBoardingStart(-1);
    }
  }, [onBoardingStart]);

  useEffect(() => {
    let startIndex = qs.parse(window.location.search, { ignoreQueryPrefix: true }).onBoardingStart;
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }
  }, []);

  useEffect(() => {
    let startIndex = queryParams.get("onBoardingStart");
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }
  }, [queryParams]);

  return (
    <>
      <Utils.Container verticalCenter={false}>
        <Utils.Card width="100%">
          <div className={style.container}>
            {user && isAdmin(user) && (
              <HomeContainer title="Premiers pas">
                <ContainerTutorial />
              </HomeContainer>
            )}
            <HomeContainer title="Vidéo explicative">
              <ContainerVideo />
            </HomeContainer>
            {/* <HomeContainer title="Parrainage">
              <ContainerSponsorship />
            </HomeContainer> */}
            <HomeContainer title="Mises à jour" handleClick={() => setIsModalUpdateOpen(true)}>
              <ContainerPatchNotes />
            </HomeContainer>
            {/* <div className={style.homeContainerEmpty}></div> */}

            {/* <HomeContainer title="Applications mobile">
              <ContainerMobileApps />
            </HomeContainer> */}
            {/* <div className={style.homeContainerEmpty}></div>
            <div className={style.homeContainerEmpty}></div> */}
            {/* <HomeContainer title="Applications connectables">
              <ContainerLinkableApps />
            </HomeContainer>
          */}
          </div>
        </Utils.Card>
      </Utils.Container>

      <Utils.Modal isOpen={isModalUpdateOpen} onClose={() => setIsModalUpdateOpen(false)} closable title={"Mises à jour"} autoWidth>
        <div style={{ maxHeight: "50vh", maxWidth: "50rem" }}>
          {patchNotes.map((patchNote) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: 800 }}>{patchNote.title}</span>
              {patchNote.content}
              <Utils.Divider variant="gray" />
            </div>
          ))}
        </div>
      </Utils.Modal>
    </>
  );
}
